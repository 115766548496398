













import { Component, Vue, VModel, Prop } from 'vue-property-decorator'
import { Freq } from '@tada-team/tdproto-ts'
import { meetingsStore } from '@/store'

@Component({
  components: {
    TdToggle: () => import('td-ui/dist/TdToggle.vue'),
  },
})
export default class RepeatabilitySwitcher extends Vue {
  @VModel({
    required: true,
    type: Boolean,
  }) isRepeatability!: boolean

  @Prop({
    type: Object,
  }) readonly freq: Freq | undefined

  private changeIsRepeatability (val: boolean) {
    if (this.isEditingMeeting && !val) {
      this.$q.dialog({
        component: () => import('../../components/ConfirmDialog.vue'),
        parent: this,
        okCaption: this.$t('meetings.repeatability.confirm.ok').toString(),
        cancelCaption: this.$t('meetings.repeatability.confirm.cancel').toString(),
        title: this.$t('meetings.repeatability.confirm.title', {
          description: this.freq?.repeatabilityDescription ?? '',
        }).toString(),
        description: this.$t('meetings.repeatability.confirm.description').toString(),
      }).onOk(() => {
        this.isRepeatability = val
      })
      return
    }
    this.isRepeatability = val
  }

  private get isEditingMeeting () {
    return !!meetingsStore.state.editingMeeting
  }
}
